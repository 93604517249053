import React from 'react';
import { Section, SectionAlternate } from 'components/organisms';
import { OnboardHero, OnboardBody } from 'components/windrate';
import { makeStyles } from '@material-ui/styles';

import pagedata from "content/pages/onboard.yaml";

const useStyles = makeStyles(theme => ({
  topPlaceholder: {
    paddingTop: 0,
  },
  sectionAlternate2: {
    background: 'transparent',
    backgroundImage: `linear-gradient(180deg, ${theme.palette.background.paper} 40%, ${theme.palette.primary.dark} 0%)`,
  },  
}));

const Onboard = () => {
  const classes = useStyles();



  return (
  <div>
    <Section className={classes.topPlaceholder}>
    </Section>
    <OnboardHero data={pagedata.hero} />
    <SectionAlternate>
        <OnboardBody data={pagedata.body} />
    </SectionAlternate>
  </div>
  );
}

export default Onboard;
